import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Global } from '../common/global';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  public order_details: OrdersDetails[] = [];
  baseUrl: string;
  lang:string='en';
  constructor(private http: HttpClient,private titleService:Title) { 
    this.baseUrl = Global.apiURL;
  }
  addorders(data): Observable<any> {
    return this.http.post(this.baseUrl +'/AddOrders', data);
  }
  GetOrderReviews(data): Observable<any> {
    return this.http.post(this.baseUrl +'/GetOrderReviews', data);
  }
  PostOrderReviews(data): Observable<any> {
    return this.http.post(this.baseUrl +'/PostOrderReviews', data);
  }
  GetOrdersData()
  {
    return this.http.get(this.baseUrl +'/GetOrdersData');
  }
  GetAllOrderStatus()
  {
    return this.http.get(this.baseUrl +'/GetAllOrderStatus');
  }
  GetAllOrdersDetails()
  {
    return this.http.get(this.baseUrl +'/GetAllOrdersDetails');
  }
  
  UpdateOrderStatus(data): Observable<any> {
    return this.http.post(this.baseUrl +'/UpdateOrderStatus', data);
  }
  
}
export class OrdersDetails {
  constructor(
    public productId:  number,
	public quantity:  number,
  ) { }
}