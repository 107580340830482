import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Global } from '../common/global';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl: string;
  lang:string='en';
  constructor(private http: HttpClient,private titleService:Title) { 
    this.baseUrl = Global.apiURL;
  }

  GetCategoryData()
  {
    return this.http.get(this.baseUrl +'/GetCategoryData');
  }
  AddCategory(data)
  {
    return this.http.post(this.baseUrl +'/AddCategory',data);
  }
  EditCategory(data)
  {
    return this.http.post(this.baseUrl +'/EditCategory',data);
  }

  
  
}
