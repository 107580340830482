<!--LOANLY-advertisement-->
<section class="section-padding LOANLY-advertisement parallax">
    <div class="row">
        <div class="col-12">
            <div class="advertisement-text">
                <p class=" text-white fs-20"[ngClass]="settingService.lang == 'en' ? '' : 'urdu'">
                    {{settingService.ad1}}</p>
                <h3 class="text-white"[ngClass]="settingService.lang == 'en' ? '' : 'urdu'">                    
                    {{settingService.ad2}}</h3>
                <!-- <div class="btn-group">
                    <a routerLink="/service" class="btn-first btn-submit fw-600 mb-xs-20">Our Services</a>
                    <a routerLink="/contact" class="btn-first btn-submit-fill fw-600 mb-xs-20">Start Now</a>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!-- LOANLY-advertisement end -->